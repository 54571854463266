.UserChangePassword {
  .input-form {
    border-right-style: hidden;
    border-bottom-left-radius: 0px !important;
  }

  .input-eye-icon {
    border: 1px solid #b2b2b2;
  }

  .font-text {
    font-size: 16px;
    font-weight: normal;
  }

  .confirm-input {
    border-right: none;
  }

  .confirm-input-icon {
    background-color: white;
    border-left: none;
    padding-right: 10px;
  }

  .text-validation {
    display: block;
    font-size: 14px;
  }
}
