.containerBtn {
  display: flex;
  width: 100%;
  background: white !important;
  border-radius: 4px;
  min-height: 30px;
  border: 1px solid #b2b2b2 !important;
  padding: 0 !important;
}

.dropdown-indicator {
  font-size: 1.3em;
  height: 37px;
  border-left: 0;
  fill: #b2b2b2;
  margin-right: 10px;
  line-height: 1;
  font-weight: bold;
}

.search-bar {
  width: 100%;
  border-right: 0;
  border: none;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 5px;
  color: black;
  text-align: left;
}

.dropdown-container {
  position: absolute;
  max-height: 20em;
  overflow: auto;
  border: 1px solid #d2d2d2;
  //   border-top: 0;
  width: 100%;
  background: white;
  overflow-x: hidden;
  font-size: 14px;
}

.options {
  width: 100% !important;
  display: flex !important;
  border: none !important;
  border-radius: 0;
  color: black !important;
  cursor: pointer;
  margin-top: 2px;
}

input[type='checkbox'] {
  margin-top: 5px;
  margin-right: 5px;
  accent-color: green;
}

.categoryBtn {
  margin-left: 1.5em;
}

.footer {
  position: sticky;
  text-align: right;
  width: 100% !important;
  bottom: 0;
  background: white;
  border: none;
  box-shadow: none !important;
}

.typeBox:hover {
  background-color: #f4fffb;
}

.item-count-badge {
  border: 1px solid #b2b2b2;
  border-radius: 40%;
  margin-left: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
