.OrderAllocationLogs {
  display: flex;
  position: relative;
  align-items: flex-start;
  .log-icon-container {
    display: flex;
    flex-direction: column; // Aligns the icon and line vertically
    align-items: center; // Centers the icon and line horizontally
    position: relative; // Ensures the vertical line is positioned correctly
    padding: 5;
  }
  .log-icon {
    z-index: 1;
  }

  .vertical-line {
    position: absolute;
    top: 100%;
    height: 160%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    background-color: #f4e3c7;
    z-index: 0;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-start;
  }
}
