@import '../../assets/scss/variables';
.OrderAllocation {
  background-color: white;

  // .nav-tabs {
  //   border-bottom: 1px solid #dcdcdc;
  // }
  .total-members {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 0.7rem;
    padding: 0.5rem;
    box-shadow: 0px 0px 4px 0px #00000040;
  }

  .status-dot {
    width: 12px;
    height: 12px;
    background-color: #34948e;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .total-members strong {
    color: #2b3674;
    font-size: 20px;
  }

  .total-members button {
    padding: 0;
    margin-left: 3rem;
  }

  .text-color {
    color: #667085;
  }

  .margin-custom {
    // margin-right: 1.5rem; /* Adjust the value as needed */
  }

  .borderBottomWidth {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  }

  .tab-content {
    margin-top: 15px;
  }
  .nav-tabs .nav-item.nav-link.active {
    border: 1px solid white;
    border-bottom: 3px solid $primary;
    color: $primary;
    font-family: font-semibold;
    outline: none;
  }
  .nav-tabs .nav-link {
    color: $dark-gray;
    min-width: 100px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .nav-tabs .nav-item.nav-link.active:hover {
    border-bottom: 3px solid $primary;
  }

  .nav-tabs .nav-item.nav-link:hover {
    border-bottom: 1px solid #dcdcdc;
  }

  .nav-tabs .nav-item.nav-link {
    letter-spacing: 0.5px;
    outline: none;
    padding: 10px;
  }

  .unavailable-status-dot {
    width: 12px;
    height: 12px;
    background-color: #f97066;
    border-radius: 50%;
    margin-right: 0.5rem;
    margin-left: 1.5rem;
  }

  .label-color {
    color: #344054;
  }
}
