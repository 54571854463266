.DocumentSectionConfigPage {
  .accordion-icon {
    transition: transform 0.3s ease-in-out;
  }

  .rotate-icon {
    transform: rotate(180deg);
  }
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
  .accordion-button {
    background-color: #fff;
    color: #000;
    border-color: #ddd;
  }

  .accordion-button:not(.collapsed) {
    background-color: #f8f9fa;
    color: #000;
  }

  .accordion-button:focus {
    box-shadow: none;
  }
}
