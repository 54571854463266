.MinMaxCard {
  .container {
    padding: 10px;
  }
  .spaceContainer {
    display: flex;
    justify-content: space-between;
  }
  .subContainer {
    @extend .spaceContainer;
    background-color: #ffffff;
    border-color: #d0d5dd;
    border-width: 1px;
    border-radius: 10px;
    padding: 15px;
    align-items: center;
  }

  .labelSty {
    align-self: center;
    font-size: 14px;
  }
  .limitSty {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 14px;
    align-self: center;
  }

  .hyphenSty {
    align-self: 'flex-end';
    padding-left: '10px';
    padding-right: '10px';
  }
}
