// If you want to override variables do it here
@import 'font';
@import 'variables';
@import '~animate.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~react-toggle/style.css';

//Custom
@import 'table';
@import 'button';
@import 'form-controls';
@import 'modal';
@import 'custom';
