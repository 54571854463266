.SummaryCard {
  .status-card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .card-style {
    width: auto;
    border-radius: 12px;
    cursor: pointer;
  }

  .footer {
    border: 0;
    border-top: 1px solid #e5e7eb;
    margin: 10px 0;
  }

  .card-count {
    font-size: 24px;
    font-weight: 600;
  }

  .card-amount {
    font-size: 24px;
    font-weight: 400;
  }

  .vertical-line {
    width: 2px;
    height: 30px;
    margin: 0 12px;
  }
}
