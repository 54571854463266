$table-head-bg: #475467;
$table-head-color: #ffffff;
$table-data-color: #475467;
$table-border: #eaecf0;

.OrderAllocationMembersModal {
  .table.table {
    margin-bottom: 0;

    &.rcyl-table,
    &.rcyl-table-panel {
      .filter-row {
        th {
          padding-top: 7px;
          padding-bottom: 7px;
          font-weight: normal !important;
          font-family: font-regular !important;
        }
        .search-field {
          padding-right: 6px;
          //   background-image: url('../img/search-icon.svg');
          background-repeat: no-repeat;
          background-size: 16px;
          max-width: 98%;
          font-weight: normal;
          background-origin: content-box;
          border-color: #dedede;
          background-position: right center;

          &::placeholder {
            color: #000000;
            opacity: 0.4;
          }
        }
        .react-select {
          max-width: 98%;

          .select__control {
            border-color: #dedede;
          }
          .select__placeholder {
            color: #000000;
            font-weight: normal;
            font-family: font-regular;
            opacity: 0.4;
          }
          .select__dropdown-indicator {
            padding: 4px;
          }
        }
      }

      thead tr:first-child {
        th {
          background: $table-head-bg;
          color: $table-head-color;
          border-top: 0;
          text-transform: capitalize;
          border-bottom: 0;
          font-weight: normal;
          font-size: 1rem;

          &:first-child {
            border-radius: 6px 0 0 6px;
            padding-left: 15px;
          }

          &:last-child {
            border-radius: 0 6px 6px 0;
            padding-right: 15px;
          }

          .sort-icons,
          .icons-section {
            margin-left: 5px;

            svg {
              color: #acbac1;
            }
          }
        }
      }

      tbody {
        tr:hover {
          background: #73b3431a;
        }
      }

      td,
      th {
        height: 40px;
        vertical-align: middle;
        padding-right: 8px;
        padding-left: 8px !important;

        &:first-child {
          padding-left: 15px;
        }

        &:last-child {
          padding-right: 15px;
        }

        &.quantity,
        &.amount {
          padding-left: 15px;
        }
      }

      th {
        font-family: font-semibold;
      }

      td {
        border-top: 0;
        border-bottom: 1px solid $table-border;
      }
    }

    &.rcyl-table-panel {
      thead {
        th {
          background: $table-head-bg;
          color: $table-head-color;
          border-top: 0;
          border-bottom: 0;
          font-weight: normal;
          font-size: 1rem;

          &:first-child {
            border-radius: 6px 0 0 0;
            padding-left: 15px;
          }

          &:last-child {
            border-radius: 0 6px 0 0;
            padding-right: 15px;
          }
        }
      }

      tbody {
        td {
          padding: 1.07rem;
          background-color: #ffffff;
        }
      }
    }
  }

  table.table-body-scroll {
    tbody {
      display: block;
      overflow-y: scroll;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }

  .scrollBarHide::-webkit-scrollbar {
    display: none;
  }
  .availabilityDiv {
    // width: 78px;
    width: 'auto';
    max-width: 100px;
    border-radius: 16px;
    border: 1px solid;
    text-align: center;
    padding: 4px 4px 4px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .dotView {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #abefc6;
    margin-right: 5px;
  }
  .no-data-message {
    text-align: center;
    width: 100%;
    padding: 20px; /* Adjust the padding as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .log-entry {
    display: flex;
    position: relative;

    .icon-col {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      padding: 0;

      .log-icon {
        z-index: 1;
      }

      .vertical-line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        background-color: #f4e3c7;
        z-index: 0;

        &.bottom {
          height: calc(100% - 0px);
        }
      }
    }

    h5 {
      color: #d77310;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 10px;
      color: #6c757d;
    }

    ul {
      padding-left: 20px;
      list-style-type: disc;

      li {
        margin-bottom: 5px;
        color: #6c757d;
      }
    }
  }
}

.OrderAllocationLOgs {
  .log-entry {
    display: flex;
    position: relative;

    .icon-col {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      padding: 0;

      .log-icon {
        z-index: 1;
      }

      .vertical-line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        background-color: #f4e3c7;
        z-index: 0;

        &.bottom {
          height: calc(100% - 0px);
        }
      }
    }

    h5 {
      color: #d77310;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 10px;
      color: #6c757d;
    }

    ul {
      padding-left: 20px;
      list-style-type: disc;

      li {
        margin-bottom: 5px;
        color: #6c757d;
      }
    }
  }
}
