.FinanceWorkArea {
  .sub-heading-style {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 18px;
    color: #1f2937;
  }
  .scrollable-container {
    overflow-y: auto;
    padding-right: 15px;
    background-color: red;
  }

  .date-search-field {
    padding-right: 6px;
    background-image: url('../../assets/img/CalenderIcon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    max-width: 98%;
    font-weight: normal;
    background-origin: content-box;
    border-color: #dedede;
    background-position: right center;
    display: flex;
    justify-content: space-between;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000000;
      opacity: 0.4;
      padding-left: 16px;
    }
  }
}
