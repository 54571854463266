$table-head-bg: #5b7682;
$table-head-color: #ffffff;
$table-border: #dcdcdc;
table.table {
  margin-bottom: 0;
  &.rcyl-table,
  &.rcyl-table-panel {
    .filter-row {
      th {
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: normal !important;
        font-family: font-regular !important;
      }
      .search-field {
        padding-right: 6px;
        background-image: url('../img/search-icon.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        max-width: 98%;
        font-weight: normal;
        background-origin: content-box;
        border-color: #dedede;
        background-position: right center;
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #000000;
          opacity: 0.4;
        }
      }
      .react-select {
        max-width: 98%;
        .select__control {
          border-color: #dedede;
        }
        .select__placeholder {
          color: #000000;
          font-weight: normal;
          font-family: font-regular;
          opacity: 0.4;
        }
        .select__dropdown-indicator {
          padding: 4px;
        }
      }
    }
    thead tr:first-child {
      th {
        background: $table-head-bg;
        color: $table-head-color;
        border-top: 0;
        text-transform: capitalize;
        border-bottom: 0;
        font-weight: normal;
        font-size: 1rem;
        &:not(:first-child) > div {
          //border-left: 2px solid #4d6a76;
          //padding-left: 1rem;
        }
        &:first-child {
          border-radius: 6px 0 0 6px;
          padding-left: 15px;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
          padding-right: 15px;
        }

        .sort-icons,
        .icons-section {
          margin-left: 5px;

          svg {
            color: #acbac1;
          }
        }
      }
    }
    tbody {
      tr:hover {
        background: #73b3431a;
      }
    }
    td,
    th {
      height: 40px;
      vertical-align: middle;
      padding: initial;
      // padding-left: 8px;
      padding-right: 8px;
    }
    th {
      font-family: font-semibold;
    }
    td {
      border-top: 0;
      border-bottom: 1px solid $table-border;
      padding-left: 8px !important;
      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
      .quantity,
      .amount {
        //float: right;
        padding-left: 15px;
      }
    }
  }

  &.rcyl-table-panel {
    thead {
      th {
        background: $table-head-bg;
        color: $table-head-color;
        border-top: 0;
        border-bottom: 0;
        font-weight: normal;
        font-size: 1rem;

        &:first-child {
          border-radius: 6px 0 0 0;
          padding-left: 15px;
        }

        &:last-child {
          border-radius: 0 6px 0 0;
          padding-right: 15px;
        }
      }
    }
    tbody {
      td {
        padding: 1.07rem;
        background-color: #f6faf8;
      }
    }
  }
}

table.table-body-scroll {
  tbody {
    display: block;
    overflow-y: scroll;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.scrollBarHide::-webkit-scrollbar {
  display: none;
}
