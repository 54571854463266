.rcylUpload {
  .rcyl-display-view {
    position: relative;
    max-width: 134px;
    min-width: 134px;
    padding-bottom: 16px;
    .recykalIcon {
      width: 30px;
      position: absolute;
      right: 2px;
      top: -17px;
    }
  }
  .rcyl-upload-view {
    max-width: 134px;
    .img-thumbnail {
      border: solid 1px #73b343;
      background-color: rgba(115, 179, 67, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
