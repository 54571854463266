.DocumentConfigTable {
  .action-radio-label {
    display: inline-flex;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: transparent;
  }

  .action-radio-input {
    display: none;
  }

  .action-radio-label.selected {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #73a79a;
    background: #dfece8;
    color: #156751;
  }
  .action-radio-label.selected:has(input[disabled]) {
    background: #eaecf0;
    border: 1px solid #b0b0b0;
    color: #98a2b3;
  }

  .action-radio-label.selected:has(input[disabled]) .action-radio-circle {
    border-color: #b0b0b0;
  }

  .action-radio-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #888;
    margin-right: 5px;
  }

  .action-radio-label.selected .action-radio-circle {
    border-color: #1b745d;
  }

  .action-radio-input:checked {
    border-color: #1b745d;
    background-color: white;
  }

  .empty-data {
    color: #475467;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .table td {
    background-color: white;
  }

  .table td:last-child {
    border-right: 1px solid #ddd;
  }

  .thead-dark th {
    background-color: #5b7682;
    color: #ffffff;
  }
}
