.AddDataPointModal {
  .dropdown-toggle {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    text-align: left;
    padding: 8px 12px;
    background-color: #fff;
    font-size: 14px;
    margin-left: auto;
  }
  .form-label {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
  }
  .dropdown-placeholder {
    color: gray;
  }
}
