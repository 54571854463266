.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepper {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 50px;
  align-items: center;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-icon {
  background-color: #d3d3d3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: background-color 0.3s;
}

.step.active .step-icon {
  background-color: #1b745d;
}

.step-title {
  margin-top: 8px;
  text-align: center;
  color: #000;
  position: absolute;
  top: 50px;
}

.step-bar {
  left: calc(100% + 5px); /* Add some space between the circle and the line */
  width: calc(100% - 10px); /* Adjust the width to fit the space between circles */
  height: 1.5px;
  background-color: #d3d3d3;
  transform: translateY(-50%);
}

.step-bar.active {
  background-color: #1b745d;
}

.buttons {
  margin-top: 20px;
}

button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  border: none;
  color: white;
  cursor: pointer;
}

button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}
