@import '../../assets/scss/variables';

.PurchaseOrderDetails {
  // overflow: scroll;
  // min-height: 20vh;
  .scrollBarHide::-webkit-scrollbar {
    display: none;
  }
  .search-field-saleorder {
    padding-left: 6px;
    background-image: url('../../assets/img/search-icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    max-width: 100%;
    background-color: white;
    font-weight: normal;
    background-origin: content-box;
    border-color: #d0d5dd;

    background-position: right center;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #667085;
      font-size: 16px;
      // opacity: 0.4;
    }
  }

  .search-field-saleorder-pwa {
    padding-left: 6px;
    // background-image: url('../../assets/img/search-icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    max-width: 100%;
    background-color: white;
    font-weight: normal;
    background-origin: content-box;
    border-color: #d0d5dd;

    background-position: right center;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #667085;
      font-size: 16px;
      // opacity: 0.4;
    }
  }

  .back-icon {
    cursor: pointer;
    margin-left: -10px;
    margin-top: -4px;
  }

  .badge-icon {
    letter-spacing: 0.26px;
    border-radius: 50px;
    font-family: font-semibold;
    font-size: 14px;
  }

  .form-control {
    height: 40px;
  }

  .sellerDetails {
    border: 1px solid $border_primary;

    .document-name {
      color: #344054;
      font-size: 12px;
    }

    .card {
      border-radius: 5px;
      background-color: #f2f4f7;
      border: 1px solid #d0d5dd;
    }

    .listing-link {
      text-decoration: underline;
      font-size: 16px;
      color: #1570ef;
    }
    .label-class {
      letter-spacing: 0.5px;
      font-size: 12px;
      font-family: font-semibold;
      margin-bottom: 2px;
      margin-top: 1px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: medium;
    }
  }

  .rejectDetails {
    border: 1px solid $border_error;
    background-color: $bg_error;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  .text-color {
    color: #344054;
  }

  .value-color {
    color: #475467;
  }

  .POItemDetails {
    table.table.rcyl-table-panel {
      thead tr:first-child {
        th {
          background-color: #475467;
        }
      }
      tbody {
        td {
          padding: 5px !important;
          background-color: white;
          color: #475467;
          border-bottom: 1px solid #eaecf0;
        }
      }

      td,
      th {
        padding: 1rem !important;
      }
      .total-row {
        font-family: font-bold;
        font-size: 1.2rem;
        td {
          border-bottom: 0;
          background-color: #ffffff;
        }
      }
    }
  }

  .viewDocCard {
    .react-viewer-attribute {
      width: 50% !important;
      font-size: 15px;
      margin: auto;
      padding: 10px;
      height: 75px;
      background: white;
      color: black;
    }
  }
}
