.bell-icon {
  min-height: 1em;
  min-width: 1em;
}

.bell-icon:hover,
.message-content {
  cursor: pointer;
}

.notification-number {
  background: red;
  color: white;
  position: relative;
  padding: 2px;
  border-radius: 30%;
  line-height: 1em;
  left: -0.55em;
}

.text-number {
  font-size: 1em;
}

.active-item {
  border-left: 4px solid #73b343;
  background-color: rgba(115, 179, 67, 0.1);
}

.mark-as-read {
  animation: moveToRight 5s ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes moveToRight {
  0% {
    transform: translateX(-1em);
  }
  100% {
    transform: translateX(1em);
  }
}

.mark-as-read:hover {
  animation: shake 0.81s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
