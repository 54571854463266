@import '../../assets/scss/variables';

$sidebar-bg-color: #ffffff !default;
$sidebar-color: #000000 !default;
$sidebar-width: 220px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-bg-color: $success !default;
$highlight-color: $light !default;
$hover-color: $success !default;
$submenu-bg-color: #ffffff !default;
$submenu-bg-color-collapsed: #f4fffb !default;
$icon-bg-color: #277958 !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

.AppSidebar.dark {
  $sidebar-bg-color: #1d1d1d !default;
  $sidebar-color: #adadad !default;
  $sidebar-width: 220px !default;
  $sidebar-collapsed-width: 80px !default;
  $highlight-color: #d8d8d8 !default;
  $submenu-bg-color: #2b2b2b !default;
  $submenu-bg-color-collapsed: #2b2b2b !default;
}

@import '~react-pro-sidebar/dist/scss/styles.scss';
/*==========================================
-----------------APP HEADER--------------
========================================== */
.AppHeader {
  background-color: $header-color;
  height: $header-height;
  -webkit-box-shadow: 0px 4px 6px #ececec80;
  -moz-box-shadow: 0px 10px 5px #27272780;
  @media only screen and (max-width: 1224px) {
    .app-name {
      display: none;
    }
  }
}
/*==========================================
-----------------APP SIDEBAR--------------
========================================== */
.AppSidebar.pro-sidebar {
  margin-top: $header-height;
  height: calc(100% - #{$header-height});
  .pro-menu-item:not(.child-menu-item) {
    &:hover {
      background: #328e691c;
    }
  }
  .pro-sidebar-inner {
    .pro-sidebar-layout::-webkit-scrollbar {
      display: none;
    }
    // .pro-sidebar-layout {
    //   overflow: -moz-scrollbars-none;
    // }
  }
  .pro-menu.shaped
    .pro-menu-item:not(.active):not(.submenu-active)
    > .pro-inner-item
    > .pro-icon-wrapper {
    background: #f1f1f1;
  }
  .pro-menu {
    .pro-menu-item:not(.active) {
      .pro-inner-item {
        &:hover {
          color: inherit;
        }
        &:focus {
          color: inherit;
        }
      }
    }
    .pro-menu-item:not(.pro-sub-menu) {
      > .pro-inner-item {
        padding: 10px 35px 10px 20px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      &.active {
        background: $highlight-bg-color;
        font-family: font-semibold;
        // margin-left: 4%;
        // margin-right: 4%;
        // border-radius: 8%;
      }
    }
    .pro-sub-menu {
      .pro-inner-list-item {
        background: $submenu-bg-color-collapsed;
        &.popper-element {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          padding-left: 0;
        }
      }
      &.submenu-active {
        > .pro-inner-item {
          background: $highlight-bg-color;
          // margin-left: 4%;
          // margin-right: 4%;
          // border-radius: 8%;
          color: $highlight-color !important;
          font-family: font-semibold;
          .pro-icon-wrapper {
            background: #328e691c;
          }
          .pro-arrow {
            border-color: $highlight-color !important;
          }
        }
        .pro-inner-item {
          &:hover {
            color: $highlight-bg-color;
          }
        }
        .active {
          background: transparent;
          color: $success;
          border-left: none;
        }
      }
    }
  }
}
/*==========================================
-----------------APP CONTENT--------------
========================================== */
main.AppContent {
  padding: 10px;
  margin-top: $header-height;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;

  .PageContainer.card {
    height: 100%;
    border: 0;
    // padding: 1.5rem 2rem;

    padding: 1rem 1.5rem;
  }
  @media (max-width: 767px) {
    .PageContainer.card {
      background: transparent;
      padding: 5px;
      overflow: scroll;
    }
  }
  @media (min-width: 768px) {
    .PageContainer.card {
      // overflow-y: auto;
    }
  }
}

// .userSelectionText {
//   user-select: none;
// }
