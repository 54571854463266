.ShipmentDocsConfigList {
  .badge {
    padding: 5px 10px;
    border-radius: 12px;
    background-color: #f0f0f0;
    font-weight: bold;

    &.badge-pending {
      border: 1px solid #fedf89;
      background: #fffaeb;
      color: #b54708;
    }

    &.badge-completed {
      border: 1px solid #abefc6;
      background: #ecfdf3;
      color: #067647;
    }
  }

  .table-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    overflow-x: auto;
  }

  .table-header {
    display: flex;
    flex-direction: column;
    background-color: #475467;
    color: white;
    overflow-x: auto;
    border-radius: 8px 8px 0 0;
    white-space: nowrap;
  }

  .tr {
    display: flex;
    min-width: 100%;
  }

  .th,
  .td {
    flex: 1;
    padding: 16px;
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .th {
    font-weight: bold;
  }

  .table-body {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: auto;
  }

  .table-body .tr {
    border-bottom: 1px solid #dcdcdc;
  }

  .td {
    background-color: white;
  }

  .td:last-child {
    text-align: center;
  }
}
