.ButtonLoading {
  position: relative;
  //display: flex;
  align-items: center;
  justify-content: center;
  .spinner-border {
    position: absolute;
    margin: 0 auto;
  }
}
