.SegmentLogs {
  .title-txt-sty {
    font-size: 16px;
    font-weight: normal;
  }
  .txt-font-size {
    font-size: 12px;
  }
  .sub-list {
    font-size: 14px;
    color: #667085;
  }
  .btn-border-sty {
    width: 50px;
    height: 100%;
    min-height: 50px;
  }
}
