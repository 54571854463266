.CreditLimitModal {
  //   background-color: yellow;
  .cancel-button {
    border-radius: 10px;
    border: 1px solid #d0d5dd;
    background-color: #ffffff;
    color: #344054;
    font-family: font-bold;
  }

  .btn-primary {
    &:hover {
      background-color: #ffffff;
      color: #344054;
    }
  }
}
