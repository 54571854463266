.SegmentationTable.table {
  border: 1px solid #ddd;
  height: 100vh; /* Full viewport height */
  overflow: scroll;
  width: 100%; /* Full width */
  border-radius: 8px;

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
        margin: 0 !important;
      }
    }
    background: #f2f4f7;
  }
  .filter {
    background: #48576c;
  }

  .th,
  .td {
    padding: 5px;
    border-bottom: 0px solid #ddd;
    background-color: #fff;
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      border-right: 0;
    }

    .resizer {
      display: inline-block;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;

      &.isResizing {
        background: red;
      }
    }
  }

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 2px 2px #ccc;
      background-color: #5b7682;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 0px #75757526;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 0px #75757526;
    }
  }

  .header {
    .tr:not(.filter-row) {
      .th {
        background: #475467;
        border: none;
        color: #ffffff;
        height: 40px;
        display: flex !important;
        align-items: center;
        border-right-color: #4c646f !important;
        .icons-section {
          color: #acbac1;
        }
      }
      &:first-child {
        .th:first-child {
          border-top-left-radius: 6px;
        }
        .th:last-child {
          border-top-right-radius: 6px;
        }
      }
      &:nth-child(2) {
        font-size: 0.88rem;
        font-family: 'font-light';
        .th:first-child {
          border-bottom-left-radius: 6px;
        }
        .th:last-child {
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}
