@import 'variables';
html {
  font-size: 14px;
  scroll-behavior: smooth;
}
@media (max-width: 767px) {
  html {
    font-size: 12px;
  }
}

#root {
  height: 100%;
  background: $page-bg-color;
}

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  .page-padding {
    padding: 2rem;
  }
}

.app {
  height: 100%;
  display: flex;
  position: relative;
}

//CUSTOM
input:focus,
.btn:focus {
  outline: none !important;
  //border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.pointer {
  cursor: pointer !important;
}
.pointer-none {
  cursor: none !important;
}

.rounded-badge {
  padding: 3px 12px;
  border-radius: 20px;
  font-family: font-bold;
  border: solid 1px #7036c9;
  color: #7036c9;
  background-color: #faf7ff;
}

//page title style
h4 {
  font-family: font-semibold;
  font-size: 1.429rem;
  font-weight: normal;
  margin-bottom: 5px;
}
h5 {
  font-family: font-semibold;
  font-size: 1.141rem;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
.display-label {
  margin-bottom: 10px;
  .form-label {
    font-family: font-semibold;
  }
}

//card
.card-panel {
  border-radius: 7px;
}
.bg-grey {
  background-color: #f5f5f5;
}
.bg-green-light {
  background-color: rgba(191, 200, 207, 0.22);
}

//show label
.label {
  color: $label-gray;
  font-family: $font-family-base;
}

.badge.rounded {
  border-radius: 24px !important;
  width: 23px;
  height: 23px;
  line-height: 15px;
  border: 1px solid #dcdcdc;
}

//tost
.Toastify__toast-container {
  width: auto;
  .Toastify__toast {
    min-height: 51px;
    min-width: 37vw;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.border-radius {
  border-radius: 10px;
}
.border-radius-sm {
  border-radius: 5px;
}
.mandatory-astrik {
  color: red;
}
.testTag.row {
  height: 150px !important;
}

#popover-deviation {
  min-width: 540px;
}

.input-kg-view {
  background-color: white;
  border-left: none;
}
@media only screen and (max-width: 1224px) {
  .search-field {
    padding-left: 6px;
    background-image: url('../img/search-icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    max-width: 100%;
    background-color: #f0f3f5;
    font-weight: normal;
    background-origin: content-box;
    border-color: #dedede;
    background-position: right center;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000000;
      opacity: 0.4;
    }
  }
}

.Not-Due {
  width: 46px;
  height: 15px;
  font-family: SFProDisplay;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #01b844;
}
.Due {
  width: 24px;
  height: 15px;
  font-family: SFProDisplay;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #fc6262;
}
.Rectangle-1865 {
  width: 83px;
  height: 25px;
  padding: 3px 18px 5px 19px;
  border-radius: 13px;
  background-color: white;
}
.Rectangle-1866 {
  width: 83px;
  height: 25px;
  padding: 3px 18px 5px 19px;
  border-radius: 13px;
  background-color: #cbffde;
}
.Rectangle-1867 {
  width: 83px;
  height: 25px;
  padding: 3px 29px 5px 30px;
  border-radius: 13px;
  background-color: #ffe6e6;
}
svg.float-right {
  margin-top: 5px !important;
}

.clipOverFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.display-label-name {
  color: #49606d;
  font-size: 12px;
  font-family: font-regular;
}

.display-label-content {
  font-size: 14px;
  font-family: font-semibold;
}
